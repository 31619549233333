import React, { Component } from "react";
import classNames from "classnames";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Recaptcha from "react-recaptcha";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import _ from "lodash"
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { updateTranslationStatus } from "../../store/actions";

import { recaptchaSiteKey } from "../../constants";
import Button from "../UI/button/Button";
import Input from "../UI/input/Input"
import axios from "../../configAxios";
import errorHandler from "../errorPages/UserNotAuthorized";
import copyToClipboard from "../../utils/copyToClipboard";
import Spinner from "../UI/Spinner";
import ToggleSwitch from "../common/toggleSwitch";

import quickBook from "../../assests/images/quick-book-logo.png";
import helcim from "../../assests/images/helcim-logo.jpg";
import swaggerLogo from "../../assests/images/swagger-logo.png";
import awsS3Logo from "../../assests/images/AWS-S3-logo.png";
import spinnerImg from "../../assests/images/spinner.gif";
import "./OrganizationIntegrations.css";

class OrganizationIntegrations extends Component {
  constructor(props) {
    super(props);
    this.copyTimeout = null;
    this.copyResetTimeout = null;
    this.signal = axios.CancelToken.source();
    this.state = {
      currentApiKey: "",
      currentOrganizationId: "",
      loading: true,
      pageLoading: true,
      apiAccessListloading: false,
      awsFieldsLoading: true,
      disableAwsSaveButton: false,
      captchaVerified: false,
      showApiPopup: false,
      copied: false,
      apiAccessList: [],
      awsClientId: {
        elementType: "input",
        elementConfig: {
          name: "awsClientId",
          type: "text"
        },
        label: "Access Key ID",
        value: "",
        style: { minWidth: "100%", maxWidth: "100%" }
      },
      awsClientIdValue: null,
      awsClientSecret: {
        elementType: "input",
        elementConfig: {
          name: "awsClientSecret",
          type: "text"
        },
        label: "Secret Access Key",
        value: "",
        style: { minWidth: "100%", maxWidth: "100%" }
      },
      awsClientSecretValue: null,
      awsBucketName: {
        elementType: "input",
        elementConfig: {
          name: "awsBucketName",
          type: "text"
        },
        label: "Bucket Name",
        value: "",
        style: { minWidth: "100%", maxWidth: "100%" }
      },
      awsRegion: {
        elementType: "select",
        elementConfig: {
          name: "awsRegion",
          type: "text",
          options: [],
        },
        label: "Region",
        selectedOption: {},
        style: { minWidth: "100%", maxWidth: "100%" }
      }
    }
  }

  componentDidMount() {
    axios.get("/organizations/formdata", {
      cancelToken: this.signal.token
    })
      .then(res => {
        const { json } = res.data;
        this.setState({
          currentApiKey:
            json
             ? json.non_expiry_access_token_for_api_user
             : "",
          currentOrganizationId: json.current_organization.id,
          awsRegion: {
            ...this.state.awsRegion,
            elementConfig: {
              ...this.state.awsRegion,
              options: json.aws_s3_regions.map(item => {
                return { value: item, label: item };
              })
            }
          },
          loading: false,
        })
        this.getAWSFields();
      })

    this.getApiAccessList();
  }

  componentWillUnmount() {
    this.signal.cancel("Requests are being cancelled");
    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout);
    }
    if (this.copyResetTimeout)
      clearTimeout(this.copyResetTimeout);
  }

  rotateRevokeHandler = (action) => {
    this.setState({ pageLoading: true });

    if(action === "revoke"){
      axios.delete(`/oauth/token?token=${this.state.currentApiKey}`,
      {
        cancelToken: this.signal.token
      }
      ).then(res => {
          if (res.data.json.status) {
            this.setState({
              currentApiKey: "",
              pageLoading: false,
              captchaVerified: false,
            });
          } else {
            errorHandler(res);
            this.setState({
              pageLoading: false,
              captchaVerified: false,
            });
          }
      })
      .catch(err => this.setState({
        pageLoading: false,
        captchaVerified: false,
      }))
    }

    else{
      axios.post(`/oauth/generate_api_access_token`,
      {
        cancelToken: this.signal.token
      }
      )
      .then(res => {
        if (res.data.status === "valid") {
          const api_key = res.data.access_token;
          this.setState({
            currentApiKey: api_key,
            pageLoading: false,
            captchaVerified: false,
          });
        } else {
          errorHandler(res);
          this.setState({
            pageLoading: false,
            captchaVerified: false,
          });
        }
      })
      .catch(err => this.setState({
          pageLoading: false,
          captchaVerified: false,
        }))
    }
  }

  verifyCaptcha = response => {
    if (response)
      this.setState({ captchaVerified: true });
  }

  copyApiToken = () => {
    if (!this.state.captchaVerified)
      return null;
    const success = copyToClipboard(this.state.currentApiKey);
    this.setState({ copied: success });
  }

  getApiAccessList = () => {
    axios.get("/api_access", { cancelToken: this.signal.token }).then(res => {
      this.setState({ apiAccessList: res.data.api_access, apiAccessListloading: false });
    });
  };

  getAWSFields = () => {
    axios
      .get(`/organizations/${this.state.currentOrganizationId}`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          awsClientId: {
            ...this.state.awsClientId,
            value: _.isEmpty(res.data.organization.aws_client_id) ? "" : "**********"
          },
          awsClientIdValue: res.data.organization.aws_client_id,
          awsClientSecret: {
            ...this.state.awsClientSecret,
            value: _.isEmpty(res.data.organization.aws_client_secret) ? "" : "**********"
          },
          awsClientSecretValue: res.data.organization.aws_client_secret,
          awsBucketName: {
            ...this.state.awsBucketName,
            value: res.data.organization.aws_upload_bucket_name
          },
          awsRegion: {
            ...this.state.awsRegion,
            selectedOption: res.data.organization.aws_region && {
              value: res.data.organization.aws_region,
              label: res.data.organization.aws_region
            }
          },
        }, ()=>{this.setState({awsFieldsLoading: false, pageLoading: false})})
      })
  };

  apiAccessClickHandler = (apiAccessId, klassName, type, currentValue) => {
    let params = {}
    params[type] = !currentValue;
    params["klass_name"] = klassName;

    axios.put(`/api_access/${apiAccessId}`,
      params,
      {
        cancelToken: this.signal.token
      }
    ).then(res => {
      if (res.data.json.success) {
        let apiAccessList = this.state.apiAccessList;
        var foundIndex = apiAccessList.findIndex(apiAccessItem => apiAccessItem.id === apiAccessId )
        apiAccessList[foundIndex][type] = !currentValue;
        this.setState({apiAccessList: apiAccessList})

      } else {
        errorHandler(res);
      }
    })
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({
      [key]: updatedcontrols,
    });
  };

  inputChangedHandlerForAwsValue = (event, key) => {
    if (!_.startsWith(event.target.value, '*')) {
      this.setState({
        [key]: event.target.value,
      });
    }
  }

  dropdownChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["selectedOption"] = event;
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  awsSubmitHandler = () => {
    this.setState({disableAwsSaveButton: true})
    const {awsClientId, awsClientIdValue, awsClientSecret, awsClientSecretValue, awsBucketName, awsRegion, currentOrganizationId} = this.state
    let params = {}
    params["aws_client_id"] = awsClientIdValue
    params["aws_client_secret"] = awsClientSecretValue
    params["aws_upload_bucket_name"] = awsBucketName.value
    params["aws_region"] = awsRegion.selectedOption ? awsRegion.selectedOption.value : null

    axios
      .put(`/organizations/${currentOrganizationId}`,
        params,
        {
          cancelToken: this.signal.token
        }
      )
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Saved successfully!")
        } else {
          errorHandler(res);
        }
        this.setState({disableAwsSaveButton: false})
      })
      .catch((err) => {
        this.setState({disableAwsSaveButton: false})
        toast.error(_.isEmpty(err.message) ? "Something Went Wrong" : err.message)
      })
  }

  render() {
    const { currentApiKey, loading, pageLoading, awsFieldsLoading, captchaVerified, showApiPopup, copied, awsClientId, awsClientSecret, awsBucketName, awsRegion } = this.state;

    const apiAccessItems = this.state.apiAccessList;
    let apiAccessList;
    if (this.state.apiAccessListloading) {
      apiAccessList = <Spinner />;
    } else {
      apiAccessList = apiAccessItems.map(apiAccessItem => (
        <li className="list-group-item apiAccessItem rounded-0" key={apiAccessItem.id}>
          <div className="row">
            <div className="col-md-4">{apiAccessItem.klass_name}</div>
            <div className="col-md-2">
              <i className={classnames(
                  "fas fa-check-circle",
                  {
                    "text-info": apiAccessItem.read_access,
                    "text-secondary element-disabled": !apiAccessItem.read_access,
                  }
                )}
                style={{cursor:'pointer'}}
                onClick={() => this.apiAccessClickHandler(apiAccessItem.id, apiAccessItem.klass_name, "read_access", apiAccessItem.read_access)}
              />
            </div>
            <div className="col-md-2">
              <i className={classnames(
                  "fas fa-check-circle",
                  {
                    "text-info": apiAccessItem.create_access,
                    "text-secondary element-disabled": !apiAccessItem.create_access,
                  }
                )}
                style={{cursor:'pointer'}}
                onClick={() => this.apiAccessClickHandler(apiAccessItem.id, apiAccessItem.klass_name, "create_access", apiAccessItem.create_access)}
              />
            </div>
            <div className="col-md-2">
              <i className={classnames(
                  "fas fa-check-circle",
                  {
                    "text-info": apiAccessItem.update_access,
                    "text-secondary element-disabled": !apiAccessItem.update_access,
                  }
                )}
                style={{cursor:'pointer'}}
                onClick={() => this.apiAccessClickHandler(apiAccessItem.id, apiAccessItem.klass_name, "update_access", apiAccessItem.update_access)}
              />
            </div>
            <div className="col-md-2">
              <i className={classnames(
                  "fas fa-check-circle",
                  {
                    "text-info": apiAccessItem.delete_access,
                    "text-secondary element-disabled": !apiAccessItem.delete_access,
                  }
                )}
                style={{cursor:'pointer'}}
                onClick={() => this.apiAccessClickHandler(apiAccessItem.id, apiAccessItem.klass_name, "delete_access", apiAccessItem.delete_access)}
              />
            </div>
          </div>
        </li>
      ));
    }

    const ShowPopOver = (
      <Popover
        id="api-tooltip"
        hidden={captchaVerified}
      >
        <div className="p-3">
          <div className="row">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Confirm API Key access
              </h5>
              <p>
                For added security, you must check the box below to view your API Key.
              </p>
              <Recaptcha
                sitekey={recaptchaSiteKey}
                render="explicit"
                onloadCallback={() =>
                  this.setState({ captchaVerified: false })
                }
                verifyCallback={this.verifyCaptcha}
              />
            </div>
          </div>
        </div>
      </Popover>
    )

    const showAPITooltip = (
      <div className={classNames(
          {
            "d-none": captchaVerified,
            "d-inline": !captchaVerified
          }
        )}
      >
        <OverlayTrigger
          placement="top"
          trigger="click"
          overlay={ShowPopOver}
          rootClose
        >
          <span
            className="font-weight-bold text-info cursor text-underline-onhover"
            onClick={() => this.setState({ showApiPopup: !showApiPopup })}
          >
            Show
          </span>
        </OverlayTrigger>
      </div>
    )

    const copyTextPopup = (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip id="api-copy-text-popup">
            {
              copied
                ? "API Key Copied"
                : "Click to Copy"
            }
          </Tooltip>
        )}
        onExit={() => {
          this.copyResetTimeout = setTimeout(
            () => {
              if (this.state.copied)
                this.setState({ copied: false })
            }, 100)
        }}
      >
        <span
          className="font-weight-bold text-info cursor text-underline-onhover"
          onClick={this.copyApiToken}
        >
          Copy
        </span>
      </OverlayTrigger>
    );

    const awsFields = (
      <>
      <div className="col-12">
        <div className="form-row">
          <div className="col-lg-6">
            <Input
              {...awsClientId}
              changed={(event) => {
                this.inputChangedHandler(event, "awsClientId");
                this.inputChangedHandlerForAwsValue(event, "awsClientIdValue");
              }}
            />
          </div>
          <div className="col-lg-6">
            <Input
              {...awsClientSecret}
              changed={(event) => {
                this.inputChangedHandler(event, "awsClientSecret");
                this.inputChangedHandlerForAwsValue(event, "awsClientSecretValue");
              }}
            />
          </div>
          <div className="col-lg-6">
            <Input
              {...awsBucketName}
              changed={event => this.inputChangedHandler(event, "awsBucketName")}
            />
          </div>
          <div className="col-lg-6">
            <Input
              {...awsRegion}
              changed={event => this.dropdownChangedHandler(event, "awsRegion")}
            />
          </div>
        </div>
      </div>
      <div className="col">
        <Button
          type="primary"
          clicked={this.awsSubmitHandler}
          disabled={this.state.disableAwsSaveButton}
        >
          Save
        </Button>
      </div>
    </>
    )

    return (
      <div className="organization-integration p-3">
        <div className="row border-bottom">
          <div className="col-12">
            <h5 className="font-weight-bold">
              Integrate Apps For AMS
            </h5>
          </div>
        </div>

        { pageLoading
        ? (
          <div className="row">
            <div className="col-sm-12">
              <img
                src={spinnerImg}
                alt="Loading..."
                className="mx-auto d-block"
              />
            </div>
          </div>
        ) : (
        <div className="row">
          <div className="col-lg-6 mt-4">
            <Link
              className="text-decoration-none cursor"
              to="/system_settings/accounting/quickbooks"
            >
              <div className="card border text-center border-link-hover">
                <div className="card-body">
                  <div>
                    <img
                      className="logo-image"
                      src={quickBook}
                      alt="QuickBooks"
                    />
                  </div>
                  <div className="mt-3">
                    <h4 className="font-weight-bold m-0 text-color-reset">QuickBooks</h4>
                  </div>
                  <div className="mt-2 text-color-reset">
                    <p className="m-0">Online Accounting Software</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 mt-4">
            <Link
              className="text-decoration-none cursor"
              to="/system_settings/invoice/online_payment"
            >
              <div className="card border text-center border-link-hover">
                <div className="card-body">
                  <div>
                    <img className="logo-image" src={helcim} alt="Helcim" />
                  </div>
                  <div className="mt-3">
                    <h4 className="font-weight-bold m-0 text-color-reset">Helcim</h4>
                  </div>
                  <div className="mt-2 text-color-reset">
                    <p className="m-0">Online payment processing</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 mt-4">
            <div className="card border">
              <div className="card-body">
                <div className="form-row">
                  <div className='col-lg-9'>
                    <label htmlFor={`toggle-localize`} className="mr-2 font-weight-bold">
                      Enable French Translation (Beta)
                    </label>
                    <p className="">Allow users to switch language settings between English and French</p>
                  </div>
                  <div className='col-lg-3'>
                    <div className='float-right mr-3 mt-4'>
                      <ToggleSwitch
                        id={`toggle-localize`}
                        className="custom-control-input"
                        checked={this.props.localize}
                        onChange={() => {
                          this.props.updateTranslationStatus(!this.props.localize, this.state.currentOrganizationId)
                        }}
                        customCheckContent="YES"
                        customUncheckContent="NO"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="card border">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="d-flex align-items-center mb-lg-0 mb-3">
                      <div className="mr-3">
                        <img
                          className="logo-image"
                          src={swaggerLogo}
                          alt="API Tokens"
                        />
                      </div>
                      <div>
                        <h4
                          className="font-weight-bold m-0"
                        >
                          API Key
                        </h4>
                      </div>
                    </div>
                  </div>
                { loading
                  ? (
                    <div className="col-lg-8">
                      <img
                        src={spinnerImg}
                        alt="Loading..."
                        className="mx-auto d-block"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-4 mt-lg-0 mt-3">
                        { !currentApiKey
                          ? "No Active API key"
                          : (
                            <>
                              <div>
                                {
                                  !captchaVerified
                                    ? "*".repeat(30)
                                    : currentApiKey
                                }
                              </div>
                              <div>
                                {showAPITooltip}
                                <span
                                  className={classNames(
                                    "font-weight-bold text-info cursor text-underline-onhover",
                                    { "d-none": !captchaVerified }
                                  )}
                                  onClick={() => this.setState({ captchaVerified: false })}
                                >
                                  Hide
                                </span>
                                <span className="mx-1">
                                  |
                                </span>
                                {
                                  captchaVerified
                                    ? (
                                      <>
                                        {copyTextPopup}
                                      </>
                                    ) : (
                                      <span
                                        className="font-weight-bold text-muted cursor-not-allowed"
                                      >
                                        Copy
                                      </span>
                                    )
                                }
                              </div>
                            </>
                          )
                        }
                      </div>
                      <div className="col-lg-4 text-lg-right mt-lg-0 mt-3">
                        <div className="dropdown">
                          <Button
                            className="dropdown-toggle"
                            buttonConfig={{
                              id: "api-action-dropdown",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            }}
                          >
                            Actions
                          </Button>
                          <div className="dropdown-menu" aria-labelledby="api-action-dropdown">
                            <button
                              className="dropdown-item cursor py-2"
                              type="button"
                              onClick={() => this.rotateRevokeHandler("rotate")}
                            >
                              Create or Rotate Key
                              <span className="float-right">
                                <i className="fas fa-chevron-right" />
                              </span>
                            </button>
                            <button
                              className="dropdown-item cursor py-2"
                              type="button"
                              onClick={() => this.rotateRevokeHandler("revoke")}
                            >
                              Delete and Revoke Key
                              <span className="float-right">
                                <i className="fas fa-chevron-right" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>)
                  }
                </div>
                <div className="row">
                  <div className="col-12">
                    <ul className="list-group list-group-flush mt-4">
                      <li className="list-group-item">
                        <div className="row">
                          <div className="col-md-4">
                            <span className="font-weight-bold">Model Name</span>
                          </div>
                          <div className="col-md-2">
                            <span className="font-weight-bold">Read</span>
                          </div>
                          <div className="col-md-2">
                            <span className="font-weight-bold">Create</span>
                          </div>
                          <div className="col-md-2">
                            <span className="font-weight-bold">Update</span>
                          </div>
                          <div className="col-md-2">
                            <span className="font-weight-bold">Delete</span>
                          </div>
                        </div>
                      </li>
                      {apiAccessList}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="card border">
              <div className="card-body">
                <div className="row">

                  <div className="d-flex">
                    <div className="mx-3 mb-4">
                      <img
                        style={{ height: "50px" }}
                        src={awsS3Logo}
                        alt="AWS S3"
                      />
                    </div>
                    <div>
                      <h4 className="font-weight-bold mb-4 mt-2 pt-1">
                        AWS S3
                      </h4>
                    </div>
                  </div>
                  {awsFieldsLoading ?
                  <div className="col-lg-12">
                    <img
                      src={spinnerImg}
                      alt="Loading..."
                      className="mx-auto d-block img-fluid"
                    />
                  </div>
                  :
                  awsFields
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    localize: state.organization.localize,
  }
}

export default withRouter(
  connect(mapStateToProps, { updateTranslationStatus })(OrganizationIntegrations)
)
